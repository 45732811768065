<template>
  <div class="container h-100">
    <div class="row h-100 align-items-center pt-4 pb-4">
      <div class="col-12 col-md-6 offset-md-3">
        <div class="card shadow sm">
          <div class="card-body">
            <h1 class="text-center">Reset Password</h1>
            <hr/>
            <form action="javascript:void(0)" class="row" method="post">
              <div class="form-group col-12">
                <label for="email" class="font-weight-bold">Email <span class="required">*</span></label>
                <input type="text" v-model="auth.email" name="email" id="email" placeholder="Enter your email" class="form-control">
              </div>
              <div class="form-group col-12">
                <label for="password" class="font-weight-bold">Password <span class="required">*</span></label>
                <input type="password" v-model="auth.password" name="password" id="password" placeholder="Enter password" class="form-control">
              </div>
              <div class="form-group col-12 ps-3 pe-3 pb-2">
                <label for="password_confirmation" class="font-weight-bold">Confirm Password <span class="required">*</span></label>
                <input type="password" name="password_confirmation" v-model="auth.password_confirmation" id="password_confirmation" placeholder="Enter password confirmation" class="form-control">
              </div>
              <div class="col-12 mb-2 mt-2">
                <button type="submit" :disabled="processing" @click="resetPassword" class="btn btn-primary btn-block">
                  {{ processing ? "Please wait" : "Change Password" }}
                </button>
                <p v-if="validationMessage" :class="showError ? 'required-info text-center' : 'success-info text-center'">
                  {{ validationMessage }}
                </p>
                <div v-if="validationErrors" class="global-error mt-2">
                  <ValidationErrors :errors="validationErrors"/>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import ValidationErrors from '../ValidationErrors'

export default {
  name:"ForgotPassword",
  props: ['token', 'email'],
  components: {
    'ValidationErrors': ValidationErrors
  },
  data(){
    return {
      auth:{
        email: this.email,
        password:"",
        password_confirmation:"",
        token: this.token
      },
      processing:false,
      validationMessage: '',
      showError: false,
      validationErrors: null
    }
  },
  methods:{
    async resetPassword(){
      this.processing = true
      await axios.post('api/reset-password',this.auth).then(({data})=>{
        this.validationErrors = null;
        if (data && data.success) {
          this.validationMessage = data.success;
        } else if (data && data.email) {
          this.validationMessage = data.email;
          this.showError = true;
        }
      }).catch(({response})=>{
        if (response && response.data && response.data.errors) {
          this.validationErrors = response.data.errors;
          this.processing = false;
        }
      }).finally(()=>{
        if (this.validationErrors === null) {
          const timeout = setTimeout(() => {
            this.$router.push({name: "login"}).catch(()=>{});
            this.showError = false;
            this.processing = false;
            clearTimeout(timeout);
          }, 4000);
        }
      })
    }
  }
}
</script>